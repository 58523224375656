.accordion-button {
  color: var(--text-color);
  background-color: var(--alt-white);
}

.mobile-nav-link {
  border: 1px solid var(--grey);
}

.accordion-button:not(.collapsed) {
  color: var(--text-color);
  background-color: var(--alt-white);
}

.accordion-body {
  background-color: var(--grey);
}

.blank-navlink {
  background-color: transparent !important;
  border: none !important;
  height: fit-content;
  padding: 0;
  margin-bottom: 5px;
}

#return-link {
  cursor: pointer;
}

#return-link:hover {
  text-decoration: underline;
}

.mobile-siteload {
  background: url(../images/new/energy.png), rgba(255, 255, 255, 0.3);
  background-repeat: no-repeat;
  background-position: center, center;
  background-size: 50%, 100%;
  background-blend-mode: difference;
}

.mobile-siteview-box.inverter {
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.3)),
    url("../images/new/inverter_outline.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.mobile-siteview-box.grid {
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.3)),
    url("../images/new/Pylon.png");
}

.mobile-datepicker {
  width: 80vw;
}

.mobile-datepicker > .react-datepicker__input-container input {
  height: 5vh;
  border: none;
  border-radius: 8px;
}

@media only screen and (max-width: 750px) {
  .mobile-landing-page {
    animation: fadeIn 0.6s ease !important;
  }
}

@media only screen and (max-width: 450px) {
  .map-view {
    height: 75vh !important;
  }

  .card-header {
    height: fit-content;
  }

  .card-header > h5 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 950px) {
  .siteview-sidebar {
    display: none;
  }

  .mobile-siteview-nav {
    display: block;
  }

  .battery-comp-con {
    width: 100%;
  }
}

#phone-icon {
  animation: rotatePhone 5.5s infinite;
}

@keyframes rotatePhone {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-90deg);
  }
  25.01% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

#landscape-splash {
  transition: opacity 0.3s ease-in-out;
}
