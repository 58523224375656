/* this CSS file is for styles that are global to the application */
/* any specific styling, such as those for strictly desktop or mobile views 
  will be in the relevant CSS file*/

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&family=Source+Sans+Pro:wght@300;400&display=swap");

@import url("https://fonts.cdnfonts.com/css/calculator");

/* height for parent 'root' div */
#root {
  height: 100vh;
}

/* default theme colour palette */
.default {
  --primary-blue: #4fabec;
  --secondary-blue: #285779;
  --mid-blue: #08436d;
  --dark-blue: #063455;
  --gradient-top: rgb(82, 125, 206);
  --gradient-bottom: rgb(58, 92, 155);
  --alt-white: white;
  --grey: white;
  --text-color: black;
}

/* dark theme colour palette */
/* .dark {
  --primary-blue: #373737;
  --secondary-blue: #000000;
  --mid-blue: #3f3f3f;
  --dark-blue: #282828;
  --gradient-top: #5b5b5b;
  --gradient-bottom: #2d2d2d;
  --alt-white: #494949;
  --grey: #494949;
  --text-color: white;
} */
.dark {
  --primary-blue: #253340;
  --secondary-blue: #191b2b;
  --mid-blue: #161e26;
  --dark-blue: #222733;
  --gradient-top: #8295b2;
  --gradient-bottom: #454f5e;
  --alt-white: #494949;
  --grey: #353049;
  --text-color: white;
}

/* Include padding and border in element total height/width */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* hide overflow and enable smooth scrolling */
html {
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

body {
  padding: none;
  font-family: "IBM Plex Sans", sans-serif;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    var(--primary-blue),
    var(--secondary-blue)
  );
  background-repeat: no-repeat;
  overflow-x: hidden;
  overflow-y: auto;
  /* below is depreciated but keeping here in case it is useful - enables specific scrolling
  in iOS devices */
  /* -webkit-overflow-scrolling: touch;  */
}

/* possibly irrelevant due to other styling choices, will review */
/* change body text colour to white when using dark theme */
body:not(.default) {
  color: white;
}

/* fadeIn animation declaration */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* gradient animation declaration */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* container for login page */
.login-page-con {
  display: flex;
  animation: fadeIn 0.6s ease;
  position: static !important;
  top: 0;
  left: 0;
  background: url("https://images.unsplash.com/photo-1597200381847-30ec200eeb9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"),
    linear-gradient(-45deg, var(--primary-blue), var(--gradient-top), white);
  background-blend-mode: soft-light;
  background-size: 200 200%;
  background-repeat: no-repeat;
  animation: gradient 210s ease infinite;
}

/* self explanatory */
.msp-logo {
  object-fit: cover;
  filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.5));
}

/* top navigation bar with date/time */
.topbar-nav {
  background-color: var(--mid-blue);
}

/* login form container doesn't exist anymore - hooray */

/* floating inputs - email/password */
#floatingInput,
#floatingPassword {
  color: var(--text-color);
  border: 1px solid rgba(0, 0, 0, 0.284);
  background-color: var(--grey);
}

/* animation for forgotten password page */
.forgot-password {
  animation: fadeIn 0.6s ease;
}

/* navbar buttons */
#settings-btn:hover {
  cursor: pointer;
  transform: rotate(359deg);
  transition: transform 2s cubic-bezier(0.5, 0.03, 0.84, 0.98);
}

#logout-btn {
  transition: all 0.3s ease-in-out;
}

#help-btn {
  transition: all 0.3s ease-in-out;
}

#help-btn:hover {
  cursor: pointer;
  transform: scale(110%);
}

#logout-btn:hover {
  cursor: pointer;
  transform: scale(110%);
}

/* sidebar for landing page */

.sidebar {
  max-height: 100%;
  background: linear-gradient(to top, var(--dark-blue), var(--secondary-blue));
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid var(--dark-blue);
}

#sidebar-small-text {
  color: white;
}

#add-account-link {
  color: white;
}

.muted-card {
  filter: brightness(85%);
}

/* cards on landing page that take user to site view */
.site-cards {
  height: fit-content;
  background-color: var(--alt-white);
  padding: 1rem;
  max-height: 20%;
  width: 100%;
  position: relative;
  border-bottom: 1px solid black;
  transition: all 0.2s ease;
}

.site-cards::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3%;
  background-color: var(--dark-blue);
  transition: all 0.2s ease;
}

.site-cards:nth-child(odd)::before {
  background-color: var(--gradient-bottom);
}

.site-cards:hover::before {
  transform: scaleX(1.5);
}

.site-cards:hover {
  cursor: pointer;
}

/* site view */
.site-view-container {
  padding-right: 0;
  margin-right: 0;
  width: 100%;
  height: 95vh !important;
  animation: fadeIn 0.6s ease;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.279),
    rgba(255, 255, 255, 0.3)
  );
  position: relative;
}

.site-tab-con {
  animation: fadeIn 0.6s ease;
}

.nav-link {
  color: var(--white) !important;
  background: linear-gradient(
    to bottom,
    var(--primary-blue) 10%,
    var(--gradient-bottom) 90%
  ) !important;
  margin-bottom: 0.2rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border: 1px solid black !important;
  position: relative;
  transition: all 0.2s ease;
}

.nav-link:hover::after {
  filter: brightness(110%);
  content: "";
  width: 100%;
  height: 50%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
  background-blend-mode: luminosity;
  transition: all 0.2s ease;
}

.nav-link:focus:hover {
  filter: brightness(90%);
}

.nav-link:focus {
  color: var(--text-color) !important;
  filter: brightness(70%);
}

/* declare spinning animation */
@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  55% {
    transform: rotate(200deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

/* refresh button on site view page */
#refresh_data_btn {
  cursor: pointer;
}

.refresh-spinning {
  animation: spinning 1.5s ease infinite;
}

.refresh-spinning-pause {
  animation-play-state: paused;
}

/* card styling */
.card {
  border: 2px solid var(--gradient-top);
  border-radius: 10px !important;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
  background-color: var(--alt-white);
}

.card-header {
  z-index: 1;
  color: white !important;
  background: linear-gradient(
    to bottom,
    var(--gradient-top),
    var(--gradient-bottom)
  );
}

/* loader spinner */
.spinner-loader {
  color: white;
}

/* top banner for certain pages - review name */
.ev-page-banner {
  background: linear-gradient(
    to bottom,
    var(--gradient-top),
    var(--gradient-bottom)
  );
  color: white;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.45));
}

/* grid for inverter/ac meter display */
.display-grid-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12rem;
}

.display-box-text {
  z-index: 2;
}

h3.display-box-text {
  font-weight: bold;
}

/* styles for individual boxes within inverter/ac page */

/* temperature */
.display-grid-box:nth-child(1) {
  position: relative;
}

.display-grid-box:nth-child(1)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../images/new/thermometer.png);
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.1;
  z-index: 1;
}

/* inverter state */
.display-grid-box:nth-child(2) {
  position: relative;
}

.display-grid-box:nth-child(2)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../images/new/status.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.1;
  z-index: 1;
}

/* frequency */
.display-grid-box:nth-child(3) {
  position: relative;
}

.display-grid-box:nth-child(3)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../images/new/frequency.png);
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.1;
  z-index: 1;
}

/* voltage */
.display-grid-box:nth-child(4) {
  position: relative;
}

.display-grid-box:nth-child(4)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../images/new/voltage.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.1;
  z-index: 1;
}

/* misc */
.display-grid-box:nth-child(5) {
  position: relative;
}

.display-grid-box:nth-child(5)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../images/new/energy.png);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.1;
  z-index: 1;
}

/* current */
.display-grid-box:nth-child(6) {
  position: relative;
}

.display-grid-box:nth-child(6)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../images/new/current.png);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.1;
  z-index: 1;
}

/* map view comp */
.map-view {
  height: 60vh;
}

/* sidebar for site view */
.siteview-sidebar {
  width: 16rem;
  color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  background: var(--gradient-bottom);
}

small {
  color: var(--white) !important;
}

/* date picker specific */
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__input-container > input {
  padding: none;
  text-align: center;
  width: 90% !important;
}

.date-picker {
  min-width: 5rem;
  max-width: 20rem;
}

/* page animations */
.admin-page {
  animation: fadeIn 0.6s ease-in-out;
}

.first-login-con {
  animation: fadeIn 0.6s ease-in-out;
}

.alert-container {
  animation: fadeIn 0.6s ease-in-out;
}

.report-page-con {
  animation: fadeIn 0.6s ease-in-out;
}

.site-info-con {
  animation: fadeIn 0.6s ease-in-out;
}

.loading-screen {
  animation: fadeIn 0.6s ease-in-out;
}

.settings-page {
  animation: fadeIn 0.6s ease-in-out;
}

.dashboard-main-con {
  animation: fadeIn 0.6s ease-in-out;
}

.forgot-password-con {
  animation: fadeIn 0.6s ease-in-out;
}

.help-page-con {
  animation: fadeIn 0.6s ease-in-out;
}

.pdf-export-btn {
  border: none;
  background: linear-gradient(
    to bottom,
    rgb(105, 195, 144),
    rgb(7, 99, 7)
  ) !important;
}

/* button styling when in row */
.row > .btn,
.login-btn {
  color: white;
  background: linear-gradient(
    to bottom,
    var(--gradient-top),
    var(--gradient-bottom)
  );
}

.row > .btn:hover {
  filter: brightness(80%) !important;
}

.login-btn {
  transition: all 0.2s ease;
}

.login-btn:hover {
  color: white !important;
  filter: brightness(80%);
}

/* theme switcher */
.form-switch > .form-check-input {
  background-color: var(--primary-blue);
}

/* hide mobile navigation while viewing in desktop */
.mobile-siteview-nav {
  display: none;
}

/* overlay for data loading/site switching */
.overlay {
  transition: all 0.6s linear;
}

/* battery CSS */
.batteryBorder {
  position: relative;
  background-color: var(--alt-white);
  display: flex;
  height: 20rem;
  width: 10rem;
  border: 2px solid var(--text-color);
  border-radius: 10px;
}

.batteryTop {
  background: var(--text-color);
  position: absolute;
  transform: translate(-50%, -25%);
  top: -3%;
  left: 50%;
  height: 10px;
  width: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.charge {
  background-color: green;
  border: 5px solid var(--alt-white);
  border-radius: 1rem;
  position: absolute;
  height: 0px;
  width: 100%;
  bottom: 0%;
  transition: all 1s ease;
}

.charge::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  border-radius: 10px;
}

#chargeDisplay {
  color: var(--text-color) !important;
  background: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: fit-content;
  width: fit-content;
  text-align: center;
  border: 0;
  font-size: 3rem;
  user-select: none;
}

#chargeDisplay2 {
  color: var(--text-color);
  background: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 65%;
  left: 52.2%;
  height: fit-content;
  width: fit-content;
  text-align: center;
  border: 0;
  font-size: 1.5rem;
  user-select: none;
}

#chargeDisplay3 {
  color: var(--text-color);
  background: none;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 52.2%;
  text-align: center;
  border: 0;
  font-size: 1.5rem;
  top: 72%;
}

#chargeDisplay4 {
  color: var(--text-color);
  background: none;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 52.2%;
  text-align: center;
  border: 0;
  top: 80%;
  font-size: 1.5rem;
}

#batt-num-id {
  color: var(--text-color);
  /* font-size: larger; */
  background: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 7%;
  left: 50%;
  width: 120px;
  height: 70px;
  text-align: center;
  /* left: 52.2%; */
  border: 0;
}

#chargeDisplay2:focus {
  outline: none;
}

#chargeDisplay:focus {
  outline: none;
}

#batt-num-id:focus {
  outline: none;
}

.batteryBorderL {
  position: relative;
  background-color: var(--alt-white);
  display: flex;
  height: 26rem;
  width: 12rem;
  border: 2px solid var(--text-color);
  border-radius: 10px;
}

.batteryTopL {
  background: var(--text-color);
  position: absolute;
  transform: translate(-50%, -25%);
  top: -2.2%;
  left: 50%;
  height: 12px;
  width: 35px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chargeL {
  background-color: green;
  border: 5px solid var(--alt-white);
  border-radius: 1rem;
  position: absolute;
  height: 0px;
  width: 100%;
  bottom: 0%;
  transition: all 1s ease;
}

.chargeL::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  border-radius: 10px;
}

#chargeDisplayL {
  color: var(--text-color) !important;
  background: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 48%;
  left: 50%;
  height: fit-content;
  width: fit-content;
  text-align: center;
  border: 0;
  font-size: 3.5rem;
  user-select: none;
}

#charging-indicator {
  color: var(--text-color) !important;
  background: none;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  height: fit-content;
  width: fit-content;
  border: 0;
  font-size: 1.5rem;
  left: 50%;
  top: 60%;
}

.speedometer {
  height: 200px !important;
  width: fit-content;
}

.defaultTextOutline {
  color: white;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black;
}

.darkTextOutline {
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black;
}

.overview-top-box {
  aspect-ratio: 1/1;
  width: 12rem;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.2)
  );
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.overview-bottom-box {
  margin: 1rem;
  width: 20rem;
  height: 10rem;
  border-radius: 8px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.2)
  );
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.overview-arrow {
  width: 5vh;
  opacity: 0.1;
}

.overview-arrow-active {
  width: 5vh;
  filter: invert(55%) sepia(91%) saturate(603%) hue-rotate(357deg)
    brightness(99%) contrast(90%) drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
  opacity: 1 !important;
}

.highcharts-container {
  min-height: 10rem !important;
  max-height: 14rem !important;
  /* padding: none !important; */
}

.highcharts-background {
  fill: none !important;
}

.battery-info-box {
  background-color: rgba(255, 255, 255, 0.4);
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thermometer__percent-current {
  font-size: 1rem !important;
}

.mobile-siteview-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.3));
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* height: 20rem; */
  aspect-ratio: 1/1;
  text-align: center;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

.lcd {
  font-family: "Calculator", sans-serif;
}

.graph-container {
  background-color: white;
  display: flex;
}

.overview-box-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.overview-siteload-con {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  margin-top: 5.5rem;
  margin-bottom: 3rem;
  border-radius: 8px;
  border: 2px dashed rgba(0, 0, 255, 0.3);
  height: fit-content;
  padding: 1rem;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.75);
  border: 2px solid white;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.5));
  height: fit-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-tooltip > p {
  margin: 0;
}

.error-page-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.report-page-graph-con {
  width: 100%;
  height: 20rem;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 8px;
}

.highcharts-container {
  overflow: visible !important;
  width: 100% !important;
  height: 100% !important;
}

.mobile-report-row {
  display: flex;
  flex-wrap: wrap;
}

.mobile-box {
  flex: 1 0 calc(25% - 10px);
  margin: 5px;
  background: #e9d2ff;
  border: 1px solid black;
}

#generate-report-btn {
  width: 3rem;
  height: 2rem;
  transition: all 0.3s ease-in-out !important;
  /* font-size: 0.8rem; */
  filter: hue-rotate(275deg);
  overflow: hidden;
}

#generate-report-btn:hover {
  width: 5rem;
}

.report-help-btn {
  filter: hue-rotate(300deg);
}

.report-help-btn:hover {
  filter: hue-rotate(300deg) !important;
}

#renewable-energy-con {
  z-index: 1;

  position: relative;
}

#renewable-energy-con::before {
  z-index: -1;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: url(../images/new/renewable-energy.png);
  background-repeat: no-repeat;
  background-size: 40%;
  opacity: 0.1;
  /* filter: invert(1); */
  background-position: 50%;
}

.site-card-location-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centre-vertically {
  line-height: fit-content;
}
