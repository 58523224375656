.new-login-container {
  background: url("https://images.unsplash.com/photo-1597200381847-30ec200eeb9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"),
    linear-gradient(-45deg, var(--primary-blue), var(--gradient-top), white);
  background-blend-mode: soft-light;
  background-size: 200 200%;
  background-repeat: no-repeat;
  position: absolute;
  top: 3rem;
  height: 100vh;
  animation: gradient 210s linear infinite, fadeIn 0.6s ease;
  overflow-y: auto;
  transition: all 0.5s linear;
}

.animated-logo {
  transition: filter 0.5s linear;
  animation: slideIn 1.5s ease;
}

.animated-logo:hover {
  filter: drop-shadow(0 0 0.75rem white);
}

#mobile-logo {
  padding: 2rem;
  margin-bottom: 2rem;
  animation: fadeInLogin 1s ease-in-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(75%);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeInLogin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.new-login-form {
  display: flex;
  flex-direction: column;
  /* height: 75%; */
  /* 
  margin-top: 2rem; */
  /* border-radius: 15px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.5); */
  animation: fadeInLogin 2s linear;
}

.new-login-form-mobile {
  animation: fadeInLogin 2s linear;
}

.alert-animated {
  transition: all 0.5s linear;
  animation: fadeInLogin 0.5s ease-in-out;
}
